.dashboard-container {
  display: flex;
  background-color: aquamarine;
  height: 100vh;
}
.button-container {
  display: flex;
  justify-content: flex-end;
  margin: 2.5rem;
}
.tab-panel {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
