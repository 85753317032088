.customized-bottom-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem 0.5rem 0rem;
  width: calc(100% - 300px);
  margin-left: 300px;
}
.buttons-container {
  background: #ffffff;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.12);
  width: 100%;
}
.link-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-width: 0;
  background: none;
  text-decoration: underline;
  cursor: pointer;
}
.link-style {
  font-family: "Lora-Bold";
  font-size: 24px;
  text-decoration-line: underline;
  color: #44a5ae;
}
@media (max-height: 650px) {
  .customized-bottom-container {
    padding: 1rem;
  }
}
@media (max-width: 1440px), (max-height: 421px) {
  .link-style {
    font-size: 20px;
  }
}
@media (max-width: 1024px) {
  .link-style {
    font-size: 18px;
  }
  .customized-bottom-container {
    width: 90%;
    margin-left: 90px;
  }
}
@media (max-width: 768px) {
  .customized-bottom-container {
    width: 100%;
    margin-left: 15px;
  }
}
