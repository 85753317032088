.list-item {
  display: flex;
  flex-direction: column;
  color: #57585a;
  font-size: 16;
  font-family: "Lato-Regular";
}
.list-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 93%;
  cursor: pointer;
}
.list-item-icon {
  display: flex;
  justify-content: flex-end;
}
.title {
  font-family: "Lora-Bold";
  font-size: 16px;
  line-height: 20px;
  color: #99a1b0;
}
.drawer-container {
  display: flex;
  width: 100%;
}
.drawer-content-container {
  width: 100%;
}
