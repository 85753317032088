.applicant-details-title {
  font-family: "Lora-Bold";
  font-size: 32px;
  line-height: 30px;
  color: #57585a;
}
.applicants-details-container {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: #0d4da111;
}
.details-container {
  padding: 1rem 2rem 1rem 2rem;
  display: flex;
  flex-direction: column;
}
.divider {
  border-top: 1px solid;
  border-color: #99a1b0;
  width: 100%;
  opacity: 0.15;
  margin-top: 1.5rem;
}
.basic-details {
  font-family: "Lora-Bold";
  font-size: 24px;
  line-height: 31px;
  color: #57585a;
  margin-top: 1.5rem;
}
.basic-details-description {
  font-family: "Lato-Regular";
  font-size: 18px;
  line-height: 167.5%;
  color: #99a1b0;
  width: 65%;
  margin-top: 1rem;
}
.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 55%;
}
.booking-row-input-container {
  display: flex;
  flex-direction: row;
  width: 75%;
}
.single-detail-container {
  display: flex;
  width: 55%;
  flex-direction: column;
}
.photo-label-container {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
.photo-label {
  font-family: "Lato-Regular";
  font-size: 20px;
  line-height: 167.5%;
  color: #57585a;
  margin-right: 0.2rem;
}
.photo-label-required {
  color: red;
}
.selected-file-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border: 1px dashed rgba(153, 161, 176, 0.5);
  border-radius: 5px;
  padding: 0.5rem;
}
.selected-file-name {
  font-family: "Lato-Regular";
  font-size: 16px;
  line-height: 167.5%;
  color: #57585a;
}
.identification-details-label {
  font-family: "Lato-Regular";
  font-size: 20px;
  line-height: 167.5%;
  color: #57585a;
}
.add-applicant-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.add-applicant-button {
  border: 1px dashed rgba(87, 88, 90, 0.4);
  width: 100%;
  cursor: pointer;
  margin-top: 0.5rem;
}
.add-applicant-button:hover {
  background-color: #f9fbff;
}
.flag-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.booking-column-input-container {
  width: 32.6%;
}

@media (max-width: 1440px), (max-height: 421px) {
  .applicant-details-title {
    font-size: 28px;
    line-height: 41px;
  }
  .basic-details {
    font-size: 22px;
    line-height: 28px;
  }
  .basic-details-description,
  .label {
    font-size: 16px;
  }
  .identification-details-label,
  .photo-label {
    font-size: 18px;
  }
  .booking-row-input-container {
    width: 80%;
  }
}
@media (max-width: 1024px) {
  .applicant-details-title {
    font-size: 24px;
    line-height: 32px;
  }
  .basic-details {
    font-size: 16px;
    line-height: 24px;
  }
  .basic-details-description,
  .label {
    font-size: 14px;
  }
  .identification-details-label,
  .photo-label {
    font-size: 16px;
  }
  .input-container,
  .booking-column-input-container {
    flex-direction: column;
    width: 60%;
  }
  .single-detail-container {
    width: 60%;
  }
  .booking-row-input-container {
    width: 95%;
    flex-direction: column;
  }
}
@media (max-width: 700px) {
  .input-container {
    width: 95%;
  }
  .single-detail-container {
    width: 95%;
    flex-direction: column;
  }
  .booking-column-input-container,
  .booking-row-input-container {
    width: 90%;
    flex-direction: column;
  }
}
