.create-opportunity-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 5rem;
  width: 100%;
}
@media (max-width: 1440px), (max-height: 421px) {
  .create-opportunity-container {
    justify-content: flex-start;
  }
}
@media (max-width: 1024px) {
  .create-opportunity-container {
    justify-content: flex-start;
  }
}
