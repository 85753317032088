.file-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem;
}
.file-upload-title {
  font-family: "Lato-Regular";
  font-size: 16px;
  line-height: 167.5%;
  color: #57585a;
  margin-top: 0.5rem;
}
.file-upload-description {
  font-family: "Lato-Regular";
  font-size: 16px;
  line-height: 19px;
  color: #99a1b0;
  margin-bottom: 1rem;
}
@media (max-width: 1440px), (max-height: 421px) {
  .file-upload-container {
    padding: 1rem;
  }
  .file-upload-title {
    font-size: 14px;
  }
  .file-upload-description {
    font-size: 14px;
  }
}
