.helper-text-style {
  background-color: white;
  width: 100%;
  align-self: center;
  font-family: "Lato-Regular";
  font-size: 16px;
  line-height: 19px;
  color: #ff0000;
}
.text-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.text-input-outer-label-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.3rem;
  margin-top: 2rem;
}
.text-input-outer-label {
  font-family: "Lato-Regular";
  font-size: 20px;
  line-height: 167.5%;
  color: #57585a;
}
.required-star {
  color: red;
  margin-left: 0.2rem;
  line-height: 167.5%;
}
.label {
  font-family: "Lato-Regular";
  font-size: 20px;
  line-height: 167.5%;
}
@media (max-width: 1440px), (max-height: 421px) {
  .text-input-outer-label {
    font-size: 16px;
  }
  .input-value {
    font-size: 16px;
  }
  .label {
    font-size: 18px;
  }
}
@media (max-width: 1024px) {
  .text-input-outer-label {
    font-size: 14px;
  }
  .input-value {
    font-size: 14px;
  }
  .label {
    font-size: 16px;
  }
}
