.app-container {
  position: relative;
}
@font-face {
  font-family: "Lora-Bold";
  src: local("Lora-Bold");
}
@font-face {
  font-family: "Lora-SemiBold";
  src: local("Lora-Bold");
}
@font-face {
  font-family: "Lora-Medium";
  src: local("Lora-Medium");
}
@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold");
}
@font-face {
  font-family: "Lato-Regular";
  src: local("Lato-Regular");
}
@font-face {
  font-family: "Lato-Italic";
  src: local("Lato-Italic");
}
