.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-size: contain;
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  background-color: #0d4da1;
  position: relative;
}
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  right: 2.5rem;
  position: absolute;
  border-radius: 2rem;
  cursor: pointer;
}
.image-style {
  height: 100px;
  width: 50px;
  background-color: #feca0a;
  border-radius: 2rem;
}
.down-arrow {
  margin-left: 0.2rem;
  color: white;
}
.divider {
  border-top: 1px solid;
  border-color: #99a1b0;
  width: 100%;
  opacity: 0.15;
  margin-top: 2.5rem;
}
.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem 0rem 2rem;
}
@media (max-width: 768px) {
  .header-container {
    justify-content: flex-start;
    padding-left: 2rem;
    height: 70px;
  }
}
@media (max-width: 425px) {
  .image-container {
    display: none;
  }
  .header-container {
    height: 60px;
  }
}
@media (max-width: 1440px), (max-height: 471px) {
  .profile-container {
    padding: 1rem 0.7rem 0rem 0.7rem;
  }
  .divider {
    margin-top: 1rem;
  }
  .header-container {
    height: 80px;
  }
}
