.card-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(13, 77, 161, 0.15);
  padding: 0rem 1rem 0rem 2rem;
}
@media (max-width: 768px) {
  .card-header-container {
    padding: 0rem 1rem 0rem 2rem;
  }
}
