.login-card-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 100;
  background-color: white;
  border-width: 1;
  padding: 2rem 0rem 3.2rem 0rem;
  flex-direction: column;
  border-radius: 0.5rem;
  margin: 5rem 0rem 0rem 0rem;
  max-width: 45%;
}
.login-title {
  margin-bottom: 1rem;
  font-family: "Lora-Bold";
  font-size: 24px;
  line-height: 31px;
  color: #0d4da1;
}
.enter-phone-number {
  font-family: "Lato-Regular";
  font-size: 18px;
  line-height: 22px;
  color: #57585a;
  margin-bottom: 1rem;
}
.phone-input-field {
  width: 100%;
  border-radius: 0.3rem;
  background: rgba(153, 161, 176, 0.1);
  border: 1px solid rgba(153, 161, 176, 0.5);
}
.form-container {
  display: flex;
  flex-direction: column;
}
.edit-phone-number-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1440px), (max-height: 421px) {
  .login-card-container {
    padding: 2rem 2rem 2rem 2rem;
    margin: 2.5rem;
    max-width: 43%;
  }
  .login-title {
    font-size: 20px;
    line-height: 24px;
    color: #0d4da1;
  }
  .enter-phone-number {
    font-size: 16px;
    line-height: 18px;
  }
}
