.webcam-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-direction: column;
}
.capture-image-info {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #99a1b0;
  font-family: "Lato-Regular";
  font-size: 18px;
}
.web-cam-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.12);
  width: 100%;
  margin: 0;
  margin-top: 1rem;
  padding: 1rem 2rem 1rem 2rem;
}
.link-and-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  width: 100%;
}
.capture-image-title {
  font-family: "Lato-Regular";
  font-size: 16px;
  color: #57585a;
  margin-bottom: 1rem;
}
.link-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-width: 0;
  background: none;
  color: #44a5ae;
  text-decoration: underline;
  font-size: 18;
}
@media (max-width: 1440px), (max-height: 421px) {
  .capture-image-info {
    font-size: 16px;
  }
  .capture-image-title {
    font-size: 14px;
  }
  .webcam-container {
    padding: 1rem 1.5rem 0rem 1.5rem;
  }
  .web-cam-button-container {
    padding: 1rem 1.5rem 0.5rem 1.5rem;
  }
  .link-container {
    font-size: 16;
  }
}
@media (max-width: 1024px) {
  .capture-image-info {
    font-size: 14px;
  }
  .capture-image-title {
    font-size: 12px;
  }
  .webcam-container {
    padding: 1rem 1.5rem 0rem 1.5rem;
  }
  .web-cam-button-container {
    padding: 1rem 1.5rem 0.5rem 1.5rem;
  }
  .link-container {
    font-size: 14;
  }
}
