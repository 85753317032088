.date-picker-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.date-picker-outer-label-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.3rem;
  margin-top: 2rem;
}
.date-picker-outer-label {
  font-family: "Lato-Regular";
  font-size: 20px;
  line-height: 167.5%;
  color: #57585a;
}
.required-star {
  color: red;
  margin-left: 0.2rem;
  line-height: 167.5%;
}
.label {
  font-family: "Lato-Regular";
  font-size: 20px;
  line-height: 167.5%;
}
@media (max-width: 1440px), (max-height: 421px) {
  .datePicker,
  .date-picker-outer-label {
    font-size: 16px;
  }
}
@media (max-width: 1024px) {
  .datePicker,
  .date-picker-outer-label {
    font-size: 12px;
  }
}
