.login-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
}
.background-color {
  background-color: rgba(13, 77, 161, 0.73);
  position: absolute;
  inset: 0;
}
.logo-style {
  background-size: contain;
  width: 13%;
  height: 13%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1000;
}
