.file-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50vh;
}
.file-upload-title {
  font-family: "Lato-Regular";
  font-size: 16px;
  line-height: 167.5%;
  color: #57585a;
  margin-top: 0.5rem;
}
.file-upload-description {
  font-family: "Lato-Regular";
  font-size: 16px;
  line-height: 19px;
  color: #99a1b0;
  margin-bottom: 1rem;
}
.signature-pad-description {
  font-family: "Lato-Regular";
  font-size: 16px;
  line-height: 19px;
  color: #99a1b0;
  margin-top: 0.7rem;
}
.clear-link {
  font-family: "Lora-Bold";
  font-size: 20px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #44a5ae;
  border-width: 0;
  background: none;
  display: flex;
  align-self: flex-end;
  margin-bottom: 0.2rem;
}
.signature-canvas-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.signature-pad-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 1440px), (max-height: 421px) {
  .file-upload-container {
    padding: 1rem;
  }
  .file-upload-title {
    font-size: 14px;
  }
  .file-upload-description {
    font-size: 14px;
  }
  .signature-pad-description {
    font-size: 14px;
  }
  .clear-link {
    font-size: 18px;
  }
}
