.dialog-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}
.divider {
  border-top: 1px solid rebeccapurple;
  border-color: #99a1b0;
  width: 100%;
  opacity: 0.15;
}
